$green: #a8cb5e;
$blue: #005ca5;
$blue--trans: #005ca5cc;

.middle_inner {
	margin-top: 44px;

	@media screen and (max-width: 1024px){
		margin-top: 0;
	}
}

p {
	font-size: 18px;
	line-height: 1.4;

	@media screen and (max-width: 1023px) {
		font-size: 17px;
		line-height: 1.2;
	}


	a {
		color: $blue !important;
		font-size: inherit;
		line-height: 1;
	}
}

.cmsmasters_text ul li {
	@media screen and (max-width: 1023px) {
		line-height: 1.2;
	}
}

.cmsmasters_text p:last-child {
	padding: 0
}

.btnnew {
	font-family: 'Bai Jamjuree', sans-serif;
	background: #fff !important;
	border: 2px solid $blue;
	color: $blue !important;
	padding: 8px 59px;
	font-size: 20px;
	margin-top: 27px;
	display: inline-block;
	transition: 0.5s ease all !important;

	&:hover {
		background: $blue !important;
		color: #fff !important;
		transform: translateY(-5px);
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}

	@media screen and (max-width: 1023px) {
		padding: 6px 33px;
		font-size: 16px;
		margin-top: 10px;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Bai Jamjuree', sans-serif;
	color: $blue;
	letter-spacing: 0 !important;
}

h1 {
	font-size: 40px;
	font-weight: 600;
	margin: 0 0 48px;
	line-height: 1.2;

	&::after {
		content: "";
		background: $green;
		width: 134px;
		height: 6px;
		display: block;
		transform: translateY(8px);
	}

	@media screen and (max-width: 1439px) {
		font-size: 36px;
	}

	@media screen and (max-width: 1023px) {
		font-size: 32px;
		margin: 0 0 20px;
	}

	@media screen and (max-width: 767px) {
		font-size: 26px;
		line-height: 1;

		&::after {
			width: 80px;
			height: 4px;
			transform: translateY(8px);
		}
	}
}

h2 {
	font-size: 35px;
	font-weight: 600;
	margin: 0 0 30px;
	line-height: 1;

	@media screen and (max-width: 1439px) {
		font-size: 33px;
	}

	@media screen and (max-width: 1023px) {
		font-size: 30px;
	}

	@media screen and (max-width: 767px) {
		font-size: 28px;
		
	}
}

h3 {
	font-size: 25px;
	font-weight: 600;
	margin: 0 0 28px;
	line-height: 1;

	@media screen and (max-width: 500px) {
		font-size: 20px;
		margin: 0 0 20px;
	}
}

#page.fixed_header #middle {
	//@media screen and (max-width: 1023px) {
	//	padding-top: 30px !important;
	//}
}

.home #page.fixed_header #middle {
	@media screen and (max-width: 1023px) {
		padding-top: 0px !important;
	}
}

#middle li a{
	text-decoration: underline;
	color: #005ca5;
}

#header {
	background: #fff;
}

.header_mid_outer {
	position: static !important;
}

.header_mid {
	height: auto !important;

	.header_mid_inner {
		width: 100%;
		max-width: 1560px;
		padding: 20px 30px;
		height: auto;
		display: flex;
	    flex-direction: row;
	    justify-content: space-between;
	    align-items: center;

		@media screen and (max-width: 1024px){
			position: static !important;
			padding: 12px 30px 0 !important;
		}
	}

	.logo_wrap {
		display: block;
	    height: auto !important;
	    font-size: 0;
	    line-height: 0;

	    .logo img {
			position: static;
		}
	}

	.mid_nav_wrap {
	    height: auto;
	    position: relative;
	    float: none;

		@media screen and (max-width: 1024px){
			position: absolute;
			top: 97px;
			right: 20px;
			width: 300px;
		}
	}

	&.header_mid_scroll {
		.header_mid_inner {
			padding: 11px 30px;
		}
	}
}

#header .header_mid .header_mid_inner .logo_wrap {
	@media screen and (max-width: 1024px){
		width: 108px !important;
	}

	@media screen and (max-width: 768px){
		width: 108px !important;
		display: flex;
	}
}

#header .header_mid .header_mid_inner {

	.resp_mid_nav_wrap {
		@media screen and (max-width: 768px) {
			margin: 0;
		}
	}
	.logo_wrap .logo {
		@media screen and (max-width: 768px) {
			width: unset;
		}
	}
}

#header .header_mid .resp_mid_nav_wrap .responsive_nav {
	transition: 0.3s ease all;
	@media screen and (max-width: 1024px){
		width: 25px;
		height: 25px;
		margin-top: -10px;

		&.active {
			width: 20px;
			height: 20px
		}

		&:before {
			width: 25px;
		}

		&:after {
			width: 25px;
		}
	}

	@media screen and (max-width: 768px){
		margin-top: 10px;
	}
}

#header .header_mid .header_mid_inner .resp_mid_nav_outer {
	@media screen and (max-width: 1024px){
		height: 100%;
	}
}

#cmsmasters_row_zwy0p2w74 .cmsmasters_row_outer_parent {
	padding: 0 !important;
}



.clientlogos {
	padding: 70px 0 78px;

	@media screen and (max-width: 1023px) {
		padding: 40px 0;
	}

	h2 {
		color: $blue !important;
		margin: 0 0 80px;

		@media screen and (max-width: 1023px) {
			margin: 0 0 30px;
		}


		&--after {
			content: "";
			background: #a8cb5e;
			width: 134px;
			height: 6px;
			display: block;
			margin: 0 auto;
			transform: translateY(28px);
		}

	}

	.cmsmasters_row_inner {
		width: 100%;
		max-width: 1370px;
	}

	h2.cmsmasters_heading {
		margin: 0 0 30px;
	}
}

.client-logos {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
	align-items: stretch;
    justify-content: center;
    gap: 20px;

	@media screen and (max-width: 1439px) {
		gap: 15px
	}

	.client-logo {
		width: 200px;
		background-color: #fff;
		padding: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		align-content: center;
		flex-wrap: nowrap;

		@media screen and (max-width: 1439px) {
			width: 150px;
		}

		@media screen and (max-width: 1023px) {
			width: 108px;
		}

		@media screen and (max-width: 500px) {
			width: calc(33.3333% - 10px);
		}

		@media screen and (max-width: 374px) {
			width: calc(50% - 10px);
		}
	}

    br {
    	display: none;
    }

    img {
    	display: block;
    }
}

.latestprojects-slider {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;

	.owl-wrapper {
		display: flex !important;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 10px;

		//@media screen and (max-width: 1199px) {
		//	gap: 0;
		//}
	}

	.owl-buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		padding: 0 9px !important;

		> div {
			margin: 0;
			width: auto;
			height: auto;
		}
	}

	.cmsmasters_prev_arrow,
	.cmsmasters_next_arrow {
		position: static !important;
	}
}

.quote-slider {
    width: 100%;
    max-width: 1012px;
    margin: 0 auto;
}

.section--quoteslider {
	padding: 0 30px;

	@media screen and (max-width: 767px) {
		padding: 0 15px;
	}

	h2 {
		color: #fff;
		margin: 0 0 71px;

		&::after {
			content: "";
			background: $green;
			width: 134px;
			height: 6px;
			display: block;
			margin: 0 auto;
			transform: translateY(28px);

			@media screen and (max-width: 767px) {
				transform: translateY(18px);
			}
		}

		@media screen and (max-width: 767px) {
			font-size: 24px;
			margin: 0 0 45px
		}
	}

	.cmsmasters_row_outer_parent {
		@media screen and (max-width: 1023px) {
			padding: 50px 0 !important;
		}

		@media screen and (max-width: 767px) {
			padding: 30px 0 15px !important;
		}
	}
}

.quote {

	&--quote {
		text-align: center;
		font-size: 21px;
		line-height: 1.2;
		margin: 0 0 15px;
		color: #fff;
		font-weight: 300;

		@media screen and (max-width: 1023px) {
			font-size: 20px;
		}

		@media screen and (max-width: 767px) {
			font-size: 18px;
		}

		@media screen and (max-width: 374px) {
			font-size: 16px;
		}
	}

	&--author {
		text-align: center;
		font-size: 19px;
		font-weight: 900;
		color: #fff;

		@media screen and (max-width: 1023px) {
			font-size: 17px;
		}

		@media screen and (max-width: 767px) {
			font-size: 15px;
		}

		@media screen and (max-width: 374px) {
			font-size: 14px;
		}
	}
}

.section--latestprojects {
	position: relative;
	padding: 0 30px;

	@media screen and (max-width: 767px) {
		padding: 0 20px;
	}

	@media screen and (max-width: 500px) {
		padding: 0 15px;
	}

	h2 {
		color: $blue !important;
		margin: 0 0 62px;

		&::after {
			content: "";
			background: $green;
			width: 134px;
			height: 6px;
			display: block;
			margin: 0 auto;
			transform: translateY(28px);
		}
	}

	.btnnew {
		margin: 0 auto;
	}

	.latestprojects-slider {
		padding:0 50px;
		margin: 0 0 35px;

		@media screen and (max-width: 1199px) {
			padding: 0;
		}

	}

	.owl-controls {
		top: 50% !important;
		bottom: auto !important;
	}

	.cmsmasters_column_inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
	}

	.cmsmasters_row_outer_parent {
		padding: 59px 0 34px !important;

		@media screen and (max-width: 1023px) {
			padding: 60px 0 !important;
		}
	}

	article {
		margin: 0 10px
	}

	.cmsmasters_prev_arrow {
		left: -40px !important;

		@media screen and (max-width: 767px) {
			left: -27px !important;
		}
	}

	.cmsmasters_next_arrow {
		right: -40px !important;

		@media screen and (max-width: 767px) {
			right: -27px !important;
		}
	}

	a {
		//width: 100%;
	    //height: 100%;
	    //position: absolute;
	    //display: flex;
	    //align-items: center;
	    //justify-content: center;
	    //top: 0;
	    //left: 0;
	    //padding: 20px;
		//color: #fff !important;
	}
}

#cmsmasters_row_zwy0p2w74 {
	.one_half {
		max-width: 951px !important;
	}

	.cmsmasters_row_margin {
		margin: 0 auto;
		float: none;
		width: 100%;
		max-width: 1920px;
	}
}

.imageslider {
	position: relative;

	.custom_html,
	.imageslider-split,
	.slick-list {
		height: 100%;
	}
}

.imageslider-split--rail {
	display: flex;
	flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
	height: 100%;

	.slick-track {
		height: 100%;
	}

	img {
		width: 100vw;
		height: 100%;
		object-fit: cover;
	}

	.slick-slider {
		position: static;
	}

	button {
		position: absolute;
		z-index: 1;
		font-size: 0;
		line-height: 1;
		padding: 17.5px;
		border: 0;
		background-position: 50% !important;
		background-size: 27px !important;

		@media screen and (max-width: 1023px) {
			padding: 14px;
			background-size: 20px !important;
		}

		&.slick-prev {
			right: 61px;
			bottom: 33px;
			background: url(https://cdn.maloneroofing.co.uk/wp-content/uploads/2023/12/angle-left-blue-v2.svg) no-repeat #fff;

			@media screen and (max-width: 1023px) {
				right: 54px;
			}

			@media screen and (max-width: 767px) {
				bottom: 10px;
				right: 39px;
			}
		}

		&.slick-next {
			right: 25px;
			bottom: 33px;
			background: url(https://cdn.maloneroofing.co.uk/wp-content/uploads/2023/12/angle-right-blue.svg) no-repeat #fff;

			@media screen and (max-width: 767px) {
				bottom: 10px;
				right: 10px
			}
		}
	}
}

.imageslide {
	position: relative;

	br {
		display: none;
	}

	&--link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
	}

	&--meta {
		position: absolute;
		right: 26px;
		bottom: 32px;
		background: $blue--trans;
		padding: 44px 74px 42px 42px;

		@media screen and (max-width: 1023px) {
			padding: 20px 20px 40px 20px;
		}

		@media screen and (max-width: 767px) {
			right: 10px;
			bottom: 10px;
		}

		@media screen and (max-width: 374px) {
			padding: 15px 15px 35px 15px;
		}
	}

	&--name {
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		line-height: 1;
		margin: 0 0 9px;

		@media screen and (max-width: 1023px) {
			font-size: 16px;
			margin: 0;
		}
	}

	&--cat {
		color: #fff;
		font-size: 16px;
		line-height: 1;

		@media screen and (max-width: 1023px) {
			font-size: 12px;
		}
	}
}

.slick-dots {
	display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;

    li {
    	&:before {
    		content: "";
    	}

    	button {
    		font-size: 0;
    		display: block;
    		padding: 0;
    		line-height: 1;
    		background: #80b3d7;
			border: 0;
			width: 23px;
			height: 5px;
    	}

    	&.slick-active {
    		button {
    			background: #fff;
    		}
    	}
	}
}


.logo-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 15px;

	@media screen and (max-width: 500px){
		justify-content: center;
	}

	.logo {
		width: calc(16.66667% - 13px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		align-content: center;
		flex-wrap: nowrap;
		background: #fff;
		padding: 20px;

		&:last-child {
			padding: 35px 20px;
		}

		@media screen and (max-width: 1023px) {
			width: calc(20% - 12px);
		}

		@media screen and (max-width: 767px) {
			width: calc(25% - 12px);
		}

		@media screen and (max-width: 500px) {
			width: calc(33.3333% - 12px);
			padding: 12px;
		}

		@media screen and (max-width: 375px) {
			padding: 7px;
		}

		@media screen and (max-width: 374px) {
			width: calc(50% - 7.5px);
		}
	}
}


footer {
	background: #efefef;

	a {
		color: $green;
		text-decoration: underline;
	}
}

.footer {

	&--accreds {
		padding: 67px 0 57px;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 20px;

		@media screen and (max-width: 500px) {
			padding: 30px 0;
		}

		.footer--container {
			justify-content: space-around;
			max-width: 1110px;
		}

		img {
			max-width: 20%;
			
			@media screen and (max-width: 1023px) {
				max-width: 20%;
			}

			@media screen and (max-width: 500px) {
				width: calc(36% - 20px);
				max-width: 50%;
			}
		}

	}

	&--top,
	&--bottom {
		display: flex;
		justify-content: center;
		align-items: stretch;
		padding: 11px 0;
	}

	&--top {
		width: 100%;
		border-bottom: 1px solid #fff;
		background: $blue;
		padding: 48px 0 42px;

		@media screen and (max-width: 1023px) {
			padding: 20px 0;
		}

		@media screen and (max-width: 767px) {
			padding: 15px 0;
		}
	}

	&--bottom {
		text-align: center;

		@media screen and (max-width: 500px) {
			.footer--container {
				flex-direction: column;
				gap: 5px
			}
		}
	}


	&--container {
		width: 100%;
		max-width: 1343px;
		padding: 0 30px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		//@media screen and (max-width: 1023px) {
		//	flex-direction: column;
		//}

		@media screen and (max-width: 767px) {
			padding: 0 20px;
		}

		@media screen and (max-width: 500px) {
			padding: 0 15px;
		}
	}

	&--copyrighttext {
		color: #ccc;
		font-size: 14px;

		a {
			font-size: 14px;
			color: #ccc;
			text-decoration: none;

			@media screen and (max-width: 1023px) {
				line-height: 1;
			}

			@media screen and (max-width: 500px) {
				font-size: 12px;
			}
		}

		@media screen and (max-width: 1023px) {
			line-height: 1;
		}

		@media screen and (max-width: 500px) {
			font-size: 12px;
		}
	}

	&--websiteby {
		@media screen and (max-width: 1023px) {
			line-height: 1;
		}

		a {
			font-size: 14px;
			color: #ccc;
			text-decoration: none;

			@media screen and (max-width: 1023px) {
				line-height: 1;
			}

			@media screen and (max-width: 500px) {
				font-size: 12px;
			}
		}
	}

	&--contact {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		flex-wrap: nowrap;
		justify-content: flex-start;

		@media screen and (max-width: 1023px) {
			width: 100%;
			flex-direction: column;
		}
	}

	&--social {
		display: flex;
		flex-direction: row;
		gap: 22px;

		svg {
			fill: #fff !important;
			width: 28px;
			height: 28px;
		}

		@media screen and (max-width: 1023px) {
			align-items: center;
			justify-content: center;
			align-content: center;
			width: 100%;
		}
	}

	&--interested {
		color: $green;
		font-size: 36px;
		font-family: Bai Jamjuree, sans-serif;
		margin-right: 10px;

		@media screen and (max-width: 1023px) {
			font-size: 26px;
			margin: 0 0 5px;
		}
	}

	&--options {
		color: #fff;
		line-height: 1;
		font-size: 20px;
		transform: translateY(6px);
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-content: center;
		align-items: center;
		gap: 10px;

		a {
			color: #fff;
			text-decoration: none;
			font-size: 20px;

			@media screen and (max-width: 1023px) {
				font-size: 16px;
				line-height: 1;
			}

			@media screen and (max-width: 500px) {
				font-size: 15px;
			}

		}

		@media screen and (max-width: 1023px) {
			font-size: 16px;
			align-items: baseline;
			flex-wrap: wrap;
			justify-content: center;
			gap: 0 5px;
			line-height: 1;
		}

		@media screen and (max-width: 500px) {
			font-size: 15px;
		}

	}
}

.wp-block-column p {
	margin-bottom: 20px !important;
}

.middle_content {
	padding: 50px 0;

	@media screen and (max-width: 1024px) {
		padding: 15px 0 0 ;
	}
}

.cmsmasters_open_post .cmsmasters_post_content {
	padding: 25px 0 !important;
}

.single-post {

	.content_wrap {
		width: 100%;
		max-width: 1366px;
	}

	figure.cmsmasters_img_wrap {
		display: none;
	}

	.cmsmasters_post_cont_info {
		text-align: left;
		padding: 0;
		border: 0 !important;
	}

	.cmsmasters_post_info > div {
		display: block;
	}

	h1.cmsmasters_post_title {
		color: $blue !important;
		font-size: 40px !important;
		font-weight: 600 !important;
		margin: 0 0 20px !important;
		line-height: 1.1 !important;

		@media screen and (max-width: 1439px) {
			font-size: 40px !important;
		}

		@media screen and (max-width: 1023px) {
			font-size: 32px !important;
		}

		@media screen and (max-width: 767px) {
			font-size: 26px !important;

		}
	}

	.cmsmasters_post_info {
		margin: 0;

		> div {
			text-align: left !important;
		}

		abbr {
			text-align: left;
			font-size: 12px;
			line-height: 1;
			color: #c1c1c1;
		}
	}
}

ul.navigation {
	@media only screen and (max-width: 1024px) {
		background-color: #fff !important;
	}
}

.navigation {
	> li {
		height: 99px
	}
}

nav > div > ul li {

	a {
		color: #000 !important;

		&:hover {
			color: $blue !important;
		}

		.nav_item_wrap:before {
			background-color: $green !important;
			width: 74px !important;
			height: 7px !important;
			top: unset !important;
			bottom: -22px !important;
		}
	}

	&.current-menu-item,
	&:hover {
		> a {
			color: $blue !important;
		}
	}

	> ul {
		background: #fff !important;
		box-shadow: rgba(50, 50, 105, 0.15) 0 2px 5px 0, rgba(0, 0, 0, 0.05) 0 1px 1px 0;

		li a {
			font-weight: normal !important;
		}

		&.sub-menu {
			display: flex;
			flex-direction: column;
			gap: 11px 12px;
			padding: 29px 5px 29px 26px;

			a {
				font-size: 20px;
				padding: 0 0 0 20px;
				position: relative;
				font-weight: 300 !important;
				font-size: 17px;

				&::before {
					position: absolute;
					left: 0;
					top: 8px;
					content: "";
					padding: 3px;
					background: #d6e5f1;
				}
			}
		}
	}

	&.menuitem--services > ul {
		width: 529px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		top: 93px;
		left: 12px;
		padding: 29px 5px 29px 26px;
		gap: 11px 12px;

		@media screen and (max-width: 1439px){
			left: -60px
		}

		> li {
			width: calc(43% - 12px);

			&:nth-child(even) {
				width: 57%;
			}

			a {
				font-size: 20px;
				padding: 0 0 0 20px;
				position: relative;
				font-weight: 300 !important;
				font-size: 17px;

				&::before {
					position: absolute;
					left: 0;
					top: 8px;
					content: "";
					padding: 3px;
					background: #d6e5f1;
				}
			}
		}
	}

	&:hover > ul {
		margin: 0;
	}
}

.header_mid .resp_mid_nav_wrap .resp_mid_nav:hover:before,
.header_mid .resp_mid_nav_wrap .resp_mid_nav:hover:after,
.header_mid .resp_mid_nav_wrap .resp_mid_nav:hover span,
.header_mid .cmsmasters_button:hover,
.header_mid .button:hover,
.header_mid input[type=submit]:hover,
.header_mid input[type=button]:hover,
.header_mid button:hover {
	background-color: $green;
}

.page404 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	overflow: hidden;

	* {
		font-family: Bai Jamjuree,sans-serif;
	}
}

.page404--container {
	padding: 0 30px;
}

.page404 {
	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		align-content: center;
	}

	&__logo {
		width: 100%;
		max-width: 336px;
		margin: 0 0 64px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__heading {
		color: #273d62;
		font-size: 58px;
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;
		line-height: 1.1;
		margin: 0 0 13px;

		span {
			display: block;
			font-size: 42px;
		}
	}

	&__para {
		width: 100%;
		max-width: 500px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		line-height: 1.2;
		margin: 0 0 32px;
		color: #273d62;
	}

	&__button a {
		background: $green;
		display: inline-block;
		color: #fff;
		padding: 15px 34px;
		font-size: 21px;
		line-height: 1;
		text-decoration: none;
		transition: 0.3s ease all;

		&:hover {
			background: $blue;
		}
	}

	@media screen and (max-width: 500px) {
		.page404__heading {
			font-size: 45px;
		}

		.page404__heading span {
			font-size: 29px;
		}

		.page404__para {
			font-size: 16px;
		}

		.page404__button a {
			font-size: 17px;
			padding: 12px 24px;
		}

		.page404__logo {
			margin: 0 0 34px;
		}

	}

	@media screen and (max-width: 374px) {
		.page404--container {
			padding: 0 15px;
		}

		.page404__para {
			font-size: 15px;
		}
	}
}

.blog.standard {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: flex-start;
	align-content: flex-start;
	gap: 30px;
	margin-top: 30px;

	@media screen and (max-width: 1023px) {
		gap: 20px;
	}

	article.post {
		width: calc(33.333% - 20px);
		background: #f5f9fb;
		margin: 0;
		padding: 0;
		border-radius: 3px;
		overflow: hidden;
		transition: 0.3s ease all;
		transform: translateY(0);

		@media screen and (max-width: 1023px) {
			width: calc(50% - 10px);
		}

		@media screen and (max-width: 424px) {
			width: 100%;
		}

		&:hover {
		}

		.cmsmasters_post_cont {
			text-align: left;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-content: flex-start;
			align-items: flex-start;

			img {
				width: 100%;
			}
		}

		.cmsmasters_post_header{
			margin: 0;
			padding: 20px 20px 0;

			@media screen and (max-width: 500px) {
				padding: 10px 15px 0;
			}

			.cmsmasters_post_title {
				line-height: 1;

				@media screen and (max-width: 767px) {
					line-height: 0.7;
				}

				@media screen and (max-width: 500px) {
					line-height: 0.6;
				}

				a {
					font-size: 21px;
					line-height: 1;
					font-weight: 600;
					color: $blue !important;

					@media screen and (max-width: 767px) {
						font-size: 22px;
					}

					@media screen and (max-width: 500px) {
						font-size: 20px;
					}
				}
			}
		}

		.cmsmasters_post_footer {
			margin: 0 0 20px;
			padding: 0 20px;
			text-align: left;

			@media screen and (max-width: 500px) {
				padding: 0 15px;
			}

			> div {
				display: block;
			}

			abbr {
				text-align: left;
				font-size: 12px;
				line-height: 1;
				color: #000;
			}
		}

		.cmsmasters_post_content {
			margin: 0;
			padding: 10px 20px;
			text-align: left;

			@media screen and (max-width: 500px) {
				padding: 10px 15px;
			}

			p {
				line-height: 1.1;
				font-size: 15px;

				@media screen and (max-width: 767px) {
					font-size: 14px;
				}
			}

		}

		.cmsmasters_img_wrap {
			height: 200px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 767px) {
				height: 130px;
			}

			img {
				width: 100vw;
				height: 100vh;
				object-fit: cover;
			}
		}

		a.cmsmasters_post_read_more {
			margin: auto 20px 20px;
			background: #fff;
			color: $blue;
			border: 2px solid $blue;
			padding: 8px 24px;
			text-align: center;
			display: block;
			transition: all .3s ease;
			text-transform: capitalize;
			font-size: 16px;
			font-weight: 400;
			line-height: 1;

			@media screen and (max-width: 500px) {
				margin: auto 15px 15px;
			}

			&:hover {
				background: $blue;
				color: #fff !important;
			}
		}
	}
}

.page-id-3 {
	.middle_inner {
		padding-top: 90px;
	}
}

.cmsmasters_next_arrow,
.cmsmasters_prev_arrow {
	border: 0 !important;
	transform: translateY(0) !important;

	&:hover {
		background: transparent !important;
		border: 0 !important;
	}
}

.cmsmasters_project_filter_list {
	li {
		&.current a {
			color: $green !important;
		}

		&:hover a {
			color: $green !important;
		}
	}
}

.cmsmasters_next_arrow span {
	content: url(https://cdn.maloneroofing.co.uk/wp-content/uploads/2023/11/angle-right-blue.svg);
}

.cmsmasters_prev_arrow span {
	content: url(https://cdn.maloneroofing.co.uk/wp-content/uploads/2023/11/angle-left-blue.svg);
}

.portfolio.opened-article {
	h1 {
		margin: 0;
		color: $blue !important;
		line-height: 1;
		font-size: 40px !important;

		@media screen and (max-width: 1439px) {
			font-size: 36px !important;
		}

		@media screen and (max-width: 1023px) {
			font-size: 32px !important;
		}

		@media screen and (max-width: 767px) {
			font-size: 28px !important;
		}
	}

	.cmsmasters_single_slider {
		h2 {
			color: $blue;
			font-size: 30px;
		}
	}

	.cmsmasters_owl_slider {

		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}

		.owl-buttons {
			bottom: 20px;
			right: 20px;

			@media screen and (max-width: 767px) {
				bottom: 5px;
				right: 5px;
			}

			@media screen and (max-width: 374px) {
				bottom: 0;
				right: 0;
			}
		}
		.cmsmasters_prev_arrow,
		.cmsmasters_next_arrow {
			top: 20px;
			position: absolute;

			&:hover {
				background: #fff !important;

				span {
					background: #fff !important;
				}
			}
		}
	}
}

.cmsmasters_google_map {
	@media screen and (max-width: 768px) {
		height: 400px !important;
	}

	@media screen and (max-width: 499px) {
		height: 300px !important;
	}
}
.headline_outer, .cmsmasters_notice .notice_close, .cmsmasters_button:hover, .button:hover, input[type=submit]:hover, input[type=button]:hover, button:hover, .cmsmasters_toggles .cmsmasters_toggle_title a, .post.cmsmasters_post_puzzle .puzzle_post_content_wrapper .cmsmasters_post_footer, .post.cmsmasters_post_puzzle .cmsmasters_post_cont, .cmsmasters_post_timeline .cmsmasters_post_date, .cmsmasters_wrap_pagination ul li .page-numbers, .cmsmasters_items_filter_wrap .cmsmasters_items_filter_list li.current a, body, .middle_inner, .search_wrap .search_bar_wrap .search_field:before, .search_wrap .search_bar_wrap .search_field:after, .search_wrap .search_icon_close:before, .search_wrap .search_icon_close:after, .cmsmasters_table, .cmsmasters_img.with_caption, input:not([type=submit]):not([type=button]):not([type=radio]):not([type=checkbox]), textarea, select, option, .cmsmasters_pricing_table .cmsmasters_button, .cmsmasters_quotes_grid .cmsmasters_quotes_list:after, .wpcf7 form.wpcf7-form span.wpcf7-list-item input[type=checkbox] + span.wpcf7-list-item-label:before, .cmsmasters-form-builder .check_parent input[type=checkbox] + label:before, .wpcf7 form.wpcf7-form span.wpcf7-list-item input[type=radio] + span.wpcf7-list-item-label:before, .cmsmasters-form-builder .check_parent input[type=radio] + label:before, .cmsmasters_prev_arrow, .cmsmasters_next_arrow {
	background: #fff;
}

.cmsmasters_row .cmsmasters_row_inner.cmsmasters_row_fullwidth {
	padding: 0;
}

.cmsmasters_items_filter_wrap .cmsmasters_items_filter_list {
	margin: 0;
	padding: 0;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 8px 24px;

	@media screen and (max-width: 500px) {
		gap: 5px;
	}
}

.cmsmasters_items_filter_wrap .cmsmasters_items_filter_list li {
	@media screen and (max-width: 500px) {
		width: 100%;
	}
}

.cmsmasters_items_filter_wrap .cmsmasters_items_filter_list li a {
	padding: 9px 40px!important;
	font-size: 14px;
	background: #fff;
	color: #005ca5;
	border: 2px solid #005ca5;
	text-decoration: none !important;

	@media screen and (max-width: 1199px) {
		padding: 9px 20px!important;
	}

	@media screen and (max-width: 767px) {
		padding: 6px 10px!important;
		font-size: 13px;
	}

	@media screen and (max-width: 500px) {
		width: 100%;
		line-height: 1;
		padding: 7px !important;
	}
}

.cmsmasters_items_filter_wrap .cmsmasters_items_filter_list li:hover a,
.cmsmasters_items_filter_wrap .cmsmasters_items_filter_list li.current a {
	background: #005ca5;
	color: #fff !important;
	border: 2px solid #005ca5;
	text-decoration: none !important;
}

.page-id-15195 .middle_inner,
.page-id-15168 .middle_inner {
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 1439px) {
	.post-password-form, .header_top_inner, .header_mid_inner, .header_bot_inner, .content_wrap, .headline_inner, .bottom_outer, .footer_inner, .cmsmasters_row_inner {
		width: 100%;
		max-width: 1366px;
	}
}

@media screen and (max-width: 1380px) {
	.cmsmasters_row_inner {
		padding: 0 30px;
	}
}

.malonegallery {
	width:100%;

	&--rail {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
	}

	&--item {
		@media screen and (max-width: 425px) {
			width: calc(50% - 10px);

			img {
				width: 100%;
			}
		}
	}
}

//.page-id-15212 {
//	.cmsmasters_row_inner {
//		width: 100%;
//		max-width: 1540px;
//
//	}
//}

table {
	width: 100%;
	border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
	background: #eee;
}
th {
	font-weight: bold;
}
td, th {
	padding: 6px;
	border: 1px solid #ccc;
	text-align: left;
}

@media screen and (max-width: 767px) {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr {
		border: 1px solid #ccc;
	}

	td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee !important;
		position: relative;
		padding-left: 50% !important;
	}

	td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

	.page-id-3 {
		td:nth-of-type(1):before { content: "Cookie	"; }
		td:nth-of-type(2):before { content: "Name"; }
		td:nth-of-type(3):before { content: "Purpose"; }
		td:nth-of-type(4):before { content: "More information"; }
	}

}

//.page-id-15212 {
//	h1 {
//		margin-top: 40px;
//	}
//}

.innerpage--relatedcasestudies {
	margin: 70px 0 0;

	@media screen and (max-width: 768px) {
		margin: 30px 0 0;
	}

	.cmsmasters_owl_slider {
		display: flex !important;
		flex-direction: row;
		align-items: center;
		flex-wrap: nowrap;
	}

	.cmsmasters_posts_slider.project .owl-controls {
		top: unset;
		bottom: unset;
	}
}

.wp-block-embed iframe {
	@media screen and (max-width: 500px) {
		height: 300px;
	}
}

.projectcontent {
	&--withfeatures {
		width: 100%;
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: flex-start;

		@media screen and (max-width: 767px) {
			flex-direction: column-reverse;
			gap: 30px;
		}
	}


	&--content {
		width: calc(100% - 380px);
		padding-right: 50px;

		@media screen and (max-width: 767px) {
			width: 100%;
			padding:0;
			margin: 0;
		}
	}

	&--features {
		width: 380px;
		background: #f5f9fb;
		padding: 25px 25px 16px;

		@media screen and (max-width: 767px) {
			width: 100%;
		}

		.project_details_item {
			font-size: 14px;
			line-height: 1;
			border-bottom: 1px solid #c2c2c2;

			&_title {
				font-weight: bold;
			}

			&_desc {
				line-height: 1.2;
			}
		}
	}
}

.cmsmasters_toggles.toggles_mode_accordion .cmsmasters_toggle_wrap .cmsmasters_toggle {
	border: 0 !important;
}

.cmsmasters_toggle {

	&s {
		margin: 0 0 20px;

		@media screen and (max-width: 500px) {
			margin: 0 0 15px;
		}

		&.accordion--blue {

			.cmsmasters_toggle_wrap {
				background: #f5f9fc !important;

				.cmsmasters_toggle_title a {
					background: #f5f9fc !important;
				}
			}
		}
	}

	&_wrap {
		width: 100%;
		margin: 0 0 10px;
		background: #fff;

		.cmsmasters_toggle_title {

			a {
				padding: 10px !important;
			}
		}
	}

	&_title {

		a {
			border: 0 !important;
			font-family: 'Bai Jamjuree', sans-serif !important;
			padding: 0 !important;
			color: $blue !important;
		}
	}

	&_inner {
		padding: 20px !important;
	}
}

.page--roofingcenter {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto
}

.cmsmasters_slider_project .cmsmasters_slider_project_inner,
.cmsmasters_profile .cmsmasters_img_rollover_wrap .cmsmasters_img_rollover{
	background-color: rgb(0 94 187 / 0%);
	flex-direction: column-reverse;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 20px;
	transition: 0.3s ease all !important;
	opacity: 1;
}

.cmsmasters_slider_project:hover .cmsmasters_slider_project_inner,
.cmsmasters_profile .cmsmasters_img_rollover_wrap:hover .cmsmasters_img_rollover {
	background-color: rgb(0 94 187 / 76%);
	flex-direction: column-reverse;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 20px;
	transition: 0.3s ease all !important;
	opacity: 1;
}

.cmsmasters_slider_project {
	.slider--itemlink {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
	}

	.cmsmasters_slider_project_header {
		margin:0;
		max-width: 100%;
		text-align: left;

		h4 a {
			font-size: 20px;
			font-weight: 300;

			@media screen and (max-width: 1199px) {
				font-size: 18px;
			}
		}
	}
}

.cmsmasters_project_grid .project_outer {
	overflow: visible;
}

.project_cont {
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background-color: rgb(0 94 187 / 0%);
	color: #fff;
	transition: 0.3s ease all;

	a {
		color: #fff;
		font-weight: 400;
	}

	a.project--itemlink {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.portfolio.grid article:hover .project_cont {
	background-color: rgb(0 94 187 / 76%);
}

.portfolio.grid article:hover img {
	transform: scale(1.1);
}

.portfolio.grid.perfect .project {
	padding: 0 12px !important;
}

.share_posts {
	display: none !important;
}

a.btnsmall {
	background: #fff;
	color: #005ca5 !important;
	border: 2px solid #005ca5;
	padding: 8px 24px;
	text-align: center;
	transition: all .3s ease !important;
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;

	&:hover {
		background: #005ca5 !important;
		color: #fff!important;
	}
}

.page-id-15208 {
	#cmsmasters_column_dtcbr9x2om {
		@media screen and (max-width: 768px) {
			background-size: cover !important;
			background-position: 50% !important;
		}
	}
}